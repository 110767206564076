import app from "./app.png";

function Hero() {
  return (
    <div className="max-w-5xl w-full flex flex-row align-middle items-center mt-8 md:mt-20 lg:mt-24">
      <div className="w-full flex-col text-white ">
        <h1 className="text-center text-3xl md:text-4xl lg:text-6xl mb-2 md:mb-4">
          A <span>better</span> focus mode
        </h1>
        <h2 className="text-center text-xl md:text-2xl lg:text-3xl text-yellow-400 px-10 md:px-">
          End doom scrolling and reclaim your life from digital chaos
        </h2>
        <div className="flex flex-row items-center justify-center mt-8 lg:mt-16">
          <div className="flex flex-row">
            <a
              href="https://apps.apple.com/us/app/focusmode2/id1588181209"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"
                alt="Download on the App Store"
                width="150"
              />
            </a>
          </div>
          <div className="flex flex-row ml-8">
            <a
              href="https://play.google.com/store/apps/details?id=com.focusmode"
              target="_blank"
              rel="noreferrer"
            >
              <span className>See how it works &rarr;</span>
            </a>
          </div>
        </div>
        {/* <div className="flex flex-row justify-center text-2xl w-full mt-10">
          ⭐️ ⭐️ ⭐️ ⭐️ ⭐️
        </div> */}
        <div className="flex justify-center mt-12">
          <img src={app} className="w-2/3 md:w-2/5" alt="app" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
