function Video() {
  return (
    <div className="flex flex-col items-center mb-8 max-w-5xl w-full px-6 mt-20 pt-16 border-t border-solid border-white border-opacity-10">
      <h2 className="text-yellow-400 text-3xl font-bold">How it works</h2>
      <div className="flex justify-center mt-6 w-full max-w-3xl bg-gray-100">
        <iframe
          src="https://www.youtube.com/embed/1y_kfWUCFDQ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="w-full max-w-5xl aspect-video"
        ></iframe>
      </div>
    </div>
  );
}

export default Video;
