function Footer() {
  return (
    <footer className="border-t border-solid border-white border-opacity-10 w-full max-w-5xl py-12 mt-12 flex flex-col md:flex-row md:justify-between px-10 lg:px-0">
      <ul>
        <li className="text-white text-lg mb-4">Shop</li>
        <li className="text-white text-lg mb-4">Contact</li>
        <li className="text-white text-lg mb-4">Press</li>
      </ul>
      <p className="text-white opacity-70 text-lg">
        &copy; 2024 focusmode<span className="text-yellow-400">2</span>.com
      </p>
    </footer>
  );
}

export default Footer;
