import "./App.css";
import Header from "./Header";
import Hero from "./Hero";
import Features from "./Features";
import Video from "./Video";
import Comparison from "./Comparison";
import Tag from "./Tag";
import Footer from "./Footer";

function App() {
  return (
    <div className="App flex flex-col align-middle items-center min-h-screen">
      <Header />
      <Hero />
      <Features />
      <Tag />
      <Video />
      <Comparison />
      <Footer />
    </div>
  );
}

export default App;
