import timelimit from "./timelimit.png";
import fm2block from "./fm2block.png";

function Comparison() {
  return (
    <div className="flex flex-col items-center justify-center max-w-5xl mt-16 pb-8 border-t border-solid border-white border-opacity-10 px-4 lg:px-0">
      <h2 className="text-white text-3xl font-bold mt-20 mb-4">
        Why <span className="text-yellow-400">2</span>?
      </h2>
      <div className="flex flex-col md:flex-row-reverse">
        <div className="flex flex-col mt-3 w-full md:w-3/5">
          <p className="text-white mb-4 px-4 text-lg">
            focusmode<span className="text-yellow-400">2</span> is named to
            highlight its role as an upgrade from the default focus mode that
            comes with iOS.
          </p>
          <p className="text-white mb-4 px-4 text-lg">
            While Apple’s built-in Focus Mode offers fairly basic functionality
            like silencing notifications and filtering apps, the key for
            unlocking any screen limit is always present. focusmode
            <span className="text-yellow-400">2</span> takes it several steps
            further by giving you more control, customisation, and
            accountability for what gets blocked.
          </p>
          <p className="text-white mb-4 px-4 text-lg">
            It’s not just a mode, but a comprehensive tool designed for serious
            productivity and the optional NFC tag integration adds a physical,
            intentional element for those who want even more structure.
          </p>
          <p className="text-white mb-4 px-4 text-lg">
            Overall, it’s called “<span className="text-yellow-400">2</span>”
            because it’s a clear evolution, upgrading from the standard features
            of iOS to a more powerful and flexible system for managing
            distractions and boosting your focus.
          </p>
        </div>
        <div className="flex flex-row w-full md:w-2/5">
          <div className="flex flex-col">
            <p className="text-white text-lg mb-2 text-center">iOS</p>
            <img src={timelimit} className="mr-4" alt="app" />
          </div>
          <div className="flex flex-col">
            <p className="text-lg text-white mb-2 text-center">
              focusmode<span className="text-yellow-400">2</span>
            </p>
            <img src={fm2block} className="mr-4" alt="app" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Comparison;
