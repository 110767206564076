var content = [
  {
    title: "Block the noise",
    description:
      "Block distracting apps for as long as you need with focusmode2. The app that helps you stay on track and achieve your productivity goals.",
  },
  {
    title: "Real-time progress",
    description:
      "With Live Activities integration, you can track your focus sessions right from your lock screen or Dynamic Island.",
  },
  {
    title: "Tap into focus",
    description:
      "With our (optional) NFC tags, a physical trigger transforms your environment into a productivity powerhouse, making focusing feel more intentional.",
  },
];

function Features() {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="md:grid md:grid-cols-3 gap-16 mt-8 md:mt-8 max-w-6xl p-10 rounded-2xl">
        {content.map((feature) => (
          <div className="flex flex-col items-center mb-8" key={feature.title}>
            <h3 className="text-yellow-400 text-2xl mb-2 text-center">
              {feature.title}
            </h3>
            <p className="text-white text-lg text-center">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Features;
